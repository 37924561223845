<template>
  <div class="mediclist">
    <div class="big-top">1</div>
    <div class="big-content">
      <div class="big-content-left">2</div>
      <div class="big-content-main">3</div>
      <div class="big-content-right">4</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'realtime',
  components: {},
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {}

}
</script>
<style lang="less" scoped>
.mediclist {
  height: 100%;
  width: 100%;
  .big-top {
    height: 30%;
    width: 100%;
    background-color: pink;
  }
  .big-content {
    display: flex;
    width: 100%;
    height: 70%;
    .big-content-left {
      flex: 1;
      background-color: skyblue;
    }
    .big-content-main {
      flex: 4;
      background-color: lightcoral;
    }
    .big-content-right {
      background-color: lightgreen;

      flex: 1;
    }
  }
}
</style>
